<template>
  <div class="mb-6">
    <vs-checkbox class="w-full mt-4" :checked="form.status"
      @change="updateForm({ key: 'status', value: $event.target.checked })">
      Status
    </vs-checkbox>

    <vs-checkbox class="w-full mt-4" :checked="form.no_follow" :value="form.no_follow"
      @change="updateForm({ key: 'no_follow', value: $event.target.checked })">
      Block search engines follow links on this content?
    </vs-checkbox>

    <vs-checkbox class="w-full mt-4" :checked="form.no_index" :value="form.no_index"
      @change="updateForm({ key: 'no_index', value: $event.target.checked })">
      Block search engines to show this content in search results?
    </vs-checkbox>
  </div>
</template>


<script>
import vSelect from 'vue-select'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    vSelect
  },
  computed: {
    ...mapGetters({
      form: 'blog/getFormObj',
      validation: 'validation/getValidationErrors'
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'blog/updateForm'
    })
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
